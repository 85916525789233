/* eslint-disable @typescript-eslint/no-unused-vars */
import { createMockAdminAssessments } from '__mocks__/adminAssessments';
import { createMockFoldersWithSubfolders } from '__mocks__/folders';
import { createMockLeaders } from '__mocks__/leader';
import { http, HttpResponse } from 'msw';
import { AdminAssessment } from 'types/AdminAssessment';
import { AssessmentStatus } from 'types/AssessmentStatus';

const getRandomLeaders = (leaders: Leader[], n: number): Leader[] => {
  const shuffled = leaders.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, n);
};

const getRandomFolder = (folders: Folder[], n: number): Folder[] => {
  const shuffled = folders.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, n);
};

export default function generateMocks(baseUrl?: string) {
  const lpiApiBaseUrl = `${baseUrl}/lpi/api/v4`;

  const leaderList = createMockLeaders(100);
  const folderList = createMockFoldersWithSubfolders(100);
  const assessmentList = Array.from({ length: 100 }, () => {
    const randomLeader = getRandomLeaders(leaderList, 1)[0];
    const randomFolder = getRandomFolder(folderList, 1)[0];
    return createMockAdminAssessments({
      length: 1,
      leader: randomLeader,
      folder: randomFolder,
      product: ['lpi-360', 'lpi-360-plus'],
      status: AssessmentStatus.COMPLETED,
    })[0];
  });

  return [
    http.get<never, never, { items: AdminAssessment[], count: number }>(
      `${baseUrl}/api/v4/entitlements`,
      async ({ request }) => {
        const url = new URL(request.url);

        // Query params
        const orderBy = url.searchParams.get('orderBy')?.replace(/%2B/g, '+').replace(/%2D/g, '-');
        const offset = parseInt(url.searchParams.get('offset') || '0', 10);
        const size = parseInt(url.searchParams.get('size') || '20', 10);
        const status = url.searchParams.get('status')?.split(',') || [];
        const userIds = url.searchParams.get('userIds')?.split(',') || [];
        const invitedLeaders = url.searchParams.get('invitedLeaders')?.split(',') || [];
        const products = url.searchParams.get('products')?.split(',') || [];
        const folders = url.searchParams.get('folderIds')?.split(',') || [];
        const subFolders = url.searchParams.get('subFolderIds')?.split(',') || [];

        let filteredAssessments = [...assessmentList];

        if (status.length > 0) {
          filteredAssessments = filteredAssessments.filter((assessment) =>
            status.includes(assessment.status),
          );
        }

        if (userIds.length > 0) {
          filteredAssessments = filteredAssessments.filter((assessment) =>
            userIds.includes(assessment.userId),
          );
        }

        if (invitedLeaders.length > 0) {
          filteredAssessments = filteredAssessments.filter((assessment) =>
            invitedLeaders.includes(assessment.userId),
          );
        }

        if (products.length > 0) {
          filteredAssessments = filteredAssessments.filter((assessment) =>
            products.includes(assessment.product),
          );
        }

        if (folders.length > 0) {
          filteredAssessments = filteredAssessments.filter((assessment) => {
            if (!assessment.folder) return false;
            let subFolderIds: string[] = [];

            if (subFolders[0] && assessment.folder.subFolders) {
              subFolderIds = assessment.folder.subFolders.map((subFolder) => subFolder.id);
              return folders.includes(assessment.folder.id) && subFolderIds.includes(subFolders[0]);
            }

            return folders.includes(assessment.folder.id);
          });
        }

        const fieldNameMappings: Record<string, keyof AdminAssessment | keyof Leader> = {
          LeaderFirstName: 'firstName',
          LeaderLastName: 'lastName',
          CompletedBy: 'completedAt',
        };

        if (orderBy) {
          const fields = orderBy.split(',');

          const orderByFields = fields.map((field) => {
            const direction = field.startsWith('-') ? -1 : 1;
            const fieldName = field.replace(/^[-+]/, '');

            return { fieldName, direction };
          });

          filteredAssessments.sort((a, b) => {
            for (const { fieldName, direction } of orderByFields) {
              const mappedField = fieldNameMappings[fieldName as keyof typeof fieldNameMappings];

              if (!mappedField) continue;

              let fieldA = (a as any)[mappedField];
              let fieldB = (b as any)[mappedField];

              if (typeof fieldA === 'string' && typeof fieldB === 'string') {
                fieldA = fieldA.toLowerCase();
                fieldB = fieldB.toLowerCase();
                if (fieldA < fieldB) return -1 * direction;
                if (fieldA > fieldB) return 1 * direction;
              }

              if (mappedField === 'completedAt' && fieldA && fieldB) {
                const dateA = new Date(fieldA).getTime();
                const dateB = new Date(fieldB).getTime();
                return (dateA - dateB) * direction;
              }
            }

            return 0;
          });
        }

        // Pagination
        const paginatedItems = filteredAssessments.slice(offset, offset + size);

        return HttpResponse.json({
          items: paginatedItems,
          count: filteredAssessments.length,
        });
      },
    ),
    http.get<never, never, { items: Leader[] }>(
      `${lpiApiBaseUrl}/lpi-leader/search`,
      async ({ request }) => {
        const url = new URL(request.url);

        const keyword = url.searchParams.get('keyword');
        const email = url.searchParams.get('email');

        let data = [...leaderList];

        if (email) {
          data = data.filter((leader) => leader.email.toLowerCase() === email.toLowerCase());
        }

        if (keyword) {
          const lowerCaseKeyword = keyword.toLowerCase();

          data = data.filter((leader) =>
            leader.firstName.toLowerCase().includes(lowerCaseKeyword) ||
            leader.lastName.toLowerCase().includes(lowerCaseKeyword),
          );
        }

        return HttpResponse.json({ items: data });
      },
    ),
    http.post(
      `${baseUrl}/api/v4/batch-reports`,
      async () => {
        return  HttpResponse.json(
          {
            url: '/',
          },
        );
      },
    ),
    http.post<never, any, { items: Folder[], count: number }>(
      `${baseUrl}/api/v4/folders/search`,
      async ({ request }) => {
        const url = new URL(request.url);
        const queryParams = new URLSearchParams(url.search);

        const offset = parseInt(queryParams.get('offset') || '0', 10);
        const size = parseInt(queryParams.get('size') || '10', 10);

        const requestBody = await request.json();
        const term = requestBody.request.keyword || '';

        let filteredItems = [...folderList];
        if (term) {
          filteredItems = folderList.filter((item) => (
            item.folderName.toLowerCase().includes(term)
            || item.subFolders?.some((child) => child.subFolderName.toLowerCase().includes(term))
          ));
        }

        const paginatedItems = filteredItems.slice(offset, offset + size);
        return  HttpResponse.json({ items: paginatedItems, count: paginatedItems.length });
      },
    ),
  ];
}
